import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI, { Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/iconfont/iconfont.css'
import httpRequest from './api/httpRequest'
import * as socketApi from './api/wssocket'
import * as messageType from './api/messageType'
import emotion from './api/emotion.js'
import element from './api/element.js'
import store from './store'
import * as  enums from './api/enums.js'
import * as  date from './api/date.js'
import './utils/directive/dialogDrag'

Vue.use(ElementUI)
// 挂载全局
Vue.prototype.$wsApi = socketApi
Vue.prototype.$msgType = messageType
Vue.prototype.$date = date
Vue.prototype.$http = httpRequest // http请求方法
Vue.prototype.$emo = emotion // emo表情
Vue.prototype.$elm = element // 元素操作
Vue.prototype.$enums = enums // 枚举
Vue.prototype.$eventBus = new Vue() // 全局事件
Vue.config.productionTip = false

const instance = new Vue({
    el: '#app',
    // 配置路由
    router,
    store,
    render: h => h(App),
})

const hash = window.location.hash,
    query = hash.slice(hash.indexOf('?')),
    params = new URLSearchParams(query)

const onRedirect = () => {
    if (instance.$route.name !== 'Login') {
        router.push('/login')
    }
}

if (params.get('username') && params.get('password')) {
    httpRequest({
        url: '/login',
        method: 'post',
        data: {
            userName: params.get('username'),
            password: params.get('password'),
            terminal: enums.TERMINAL_TYPE.WEB,
        },
        headers: {
            hideMsg: true
        }
    }).then(res => {
        sessionStorage.setItem('accessToken', res.accessToken)
        sessionStorage.setItem('refreshToken', res.refreshToken)

        router.push('/home/chat')
    }).catch(err => {
        instance.$loading({
            text: '网络异常，请等待'
        })
        console.log(err)
        // onRedirect()
    })
} else {
    onRedirect()
}
